import './ClientContainer.css'

const ClientContainer = () => {
  return (
    <div className='clients3'>
      <div className='clients4'>CLIENTS</div>
      <div className='lets-meet-our'>
        Let’s meet our clients who grow and soaring together with us.
      </div>
      <div className='clients-logo'>
        <img
          className='clients-logo-child'
          src='/assets/desktop-mobile/client1.png'
        />
        <img
          className='clients-logo-item'
          src='/assets/desktop-mobile/client2.png'
        />
        <img
          className='clients-logo-inner'
          src='/assets/desktop-mobile/client3.png'
        />
        <img
          className='clients-logo-child1'
          src='/assets/desktop-mobile/client4.png'
        />
        <img
          className='clients-logo-child2'
          src='/assets/desktop-mobile/client5.png'
        />
        <img
          className='clients-logo-child3'
          src='/assets/desktop-mobile/client6.png'
        />
        <img
          className='clients-logo-child4'
          src='/assets/desktop-mobile/client7.png'
        />
        <img
          className='clients-logo-child5'
          src='/assets/desktop-mobile/client8.png'
        />
        <img
          className='artboard-42-1-icon2'
          src='/assets/desktop-mobile/client10.png'
        />
        <img
          className='clients-logo-child6'
          src='/assets/desktop-mobile/client9.png'
        />
        <img
          className='clients-logo-child7'
          src='/assets/desktop-mobile/client11.png'
        />
        <img
          className='clients-logo-child8'
          src='/assets/desktop-mobile/client12.png'
        />
        <img
          className='clients-logo-child9'
          src='/assets/desktop-mobile/client13.png'
        />
        <img
          className='clients-logo-child10'
          src='/assets/desktop-mobile/client14.png'
        />
        <img
          className='clients-logo-child11'
          src='/assets/desktop-mobile/client15.png'
        />
        <img
          className='clients-logo-child12'
          src='/assets/desktop-mobile/client16.png'
        />
        <img
          className='clients-logo-child13'
          src='/assets/desktop-mobile/client17.png'
        />
        <img
          className='clients-logo-child14'
          src='/assets/desktop-mobile/client18.png'
        />
        <img
          className='clients-logo-child15'
          src='/assets/desktop-mobile/client19.png'
        />
        <img
          className='clients-logo-child16'
          src='/assets/desktop-mobile/client20.png'
        />
        <img
          className='clients-logo-child17'
          src='/assets/desktop-mobile/client21.png'
        />
        <img
          className='clients-logo-child18'
          src='/assets/desktop-mobile/client22.png'
        />
        <img
          className='clients-logo-child19'
          src='/assets/desktop-mobile/client23.png'
        />
        <img
          className='clients-logo-child20'
          src='/assets/desktop-mobile/client24.png'
        />
        <img
          className='clients-logo-child21'
          src='/assets/desktop-mobile/client25.png'
        />
        <img
          className='clients-logo-child22'
          src='/assets/desktop-mobile/client26.png'
        />
        <img
          className='clients-logo-child23'
          src='/assets/desktop-mobile/client27.png'
        />
        <img
          className='clients-logo-child24'
          src='/assets/desktop-mobile/client28.png'
        />
        <img
          className='clients-logo-child25'
          src='/assets/desktop-mobile/client29.png'
        />
        <img
          className='clients-logo-child26'
          src='/assets/desktop-mobile/client30.png'
        />
        <img
          className='clients-logo-child27'
          src='/assets/desktop-mobile/client31.png'
        />
        <img
          className='clients-logo-child28'
          src='/assets/desktop-mobile/client32.png'
        />
        <img
          className='clients-logo-child29'
          src='/assets/desktop-mobile/client33.png'
        />
        <img
          className='clients-logo-child30'
          src='/assets/desktop-mobile/client34.png'
        />
        <img
          className='clients-logo-child31'
          src='/assets/desktop-mobile/client35.png'
        />
        <img
          className='clients-logo-child32'
          src='/assets/desktop-mobile/client36.png'
        />
        <img
          className='artboard-70-1-icon2'
          src='/assets/desktop-mobile/client38.png'
        />
        <img
          className='clients-logo-child33'
          src='/assets/desktop-mobile/client37.png'
        />
        <img
          className='clients-logo-child34'
          src='/assets/desktop-mobile/client39.png'
        />
        <img
          className='clients-logo-child35'
          src='/assets/desktop-mobile/client40.png'
        />
        <img
          className='clients-logo-child36'
          src='/assets/desktop-mobile/client41.png'
        />
        <img
          className='clients-logo-child37'
          src='/assets/desktop-mobile/client42.png'
        />
      </div>
    </div>
  )
}

export default ClientContainer
